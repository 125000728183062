import type { ApiResponse, PagingResponse } from '@/interfaces'
import type { I18nProperty } from '@i2pacg/bd-vue'
import type { Response } from '@pinia-orm/axios'
import type { AxiosResponse } from 'axios'
import type ImageModel from './image.model'
import { isPagingResponse } from '@/helpers'
import {
  CastAttribute,
  Model,
  useRepo,
} from 'pinia-orm'
import { ArrayCast, DateCast } from 'pinia-orm/casts'
import {
  Attr,
  Cast,
  MorphMany,
  Str,
  Uid,
} from 'pinia-orm/decorators'
import { Project } from './project.model'

export class Service extends Model {
  static entity = 'services'
  @Uid() declare id: number | null
  @Attr() declare slug: string
  @Str('') declare cover: string
  @Attr() declare name: I18nProperty
  @Attr(null) declare projects: Project[] | null
  @Attr(null) declare description: I18nProperty | null
  @Attr() declare content: I18nProperty
  @Attr() declare tagline: I18nProperty
  @Str(null) declare icon: string | null
  @Cast(() => ImageCast) @Attr() declare images: ImageModel[]
  @Cast(() => ArrayCast) @Attr() declare services: Service[] | null
  @Cast(() => DateCast) @Attr() declare createdAt: Date
  @Cast(() => DateCast) @Attr() declare updatedAt: Date

  static casts() {
    return {
      name: I18nCast,
      description: I18nCast,
      tagline: I18nCast,
      content: I18nCast,
      projects: ProjectCast,
    }
  }

  static config = {
    crudConfig: {
      itemsPerPage: 10,
      mediaCollections: {
        images: [
          {
            fileType: 'image/*',
            dimensions: {
              width: 1920,
              height: 1080,
            },
          },
        ],
      },
      columns: {
        id: {
          type: 'number',
          title: 'columns.default.id.header',
          header: { width: 24, align: 'center' },
          cell: { type: 'number' },
          field: { type: 'number', hidden: true },
        },
        slug: {
          type: 'string',
          title: 'columns.default.slug.header',
          header: { width: 24, align: 'center' },
          cell: { type: 'string' },
          field: {
            type: 'string',
            component: 'SlugField',
          },
        },
        name: {
          type: 'string',
          translatable: true,
          title: 'columns.default.name.header',
          header: { align: 'start' },
          cell: { type: 'string' },
          field: { type: 'string', label: 'columns.default.name.field.label' },
        },
        icon: {
          type: 'icon',
          title: 'columns.default.icon.header',
          cell: { type: 'icon' },
          header: {
            width: 48,
            align: 'start',
            sortable: false,
          },
          field: { type: 'icon' },
        },
        cover: {
          type: 'image',
          title: 'columns.default.cover.header',
          header: {
            width: 24,
            align: 'center',
            sortable: false,

          },
          cell: { type: 'image' },
        },
        tagline: {
          type: 'string',
          translatable: true,
          title: 'entities.service.columns.tagline.header',
          header: { align: 'start' },
          cell: { type: 'string' },
          field: { type: 'string', label: 'entities.service.columns.tagline.label' },
        },
        content: {
          type: 'html',
          translatable: true,
          title: 'entities.service.columns.content.header',
          header: { align: 'start' },
          cell: { type: 'string' },
          field: { type: 'html', label: 'entities.service.columns.content.label' },
        },
        createdAt: {
          type: 'datetime',
          title: 'columns.default.createdAt.header',
          cell: { type: 'datetime' },
          field: { type: 'datetime' },
          header: { align: 'start' },
        },
        updatedAt: {
          type: 'datetime',
          title: 'columns.default.updatedAt.header',
          cell: { type: 'datetime' },
          field: { type: 'datetime' },
          header: { align: 'start' },
        },
      },
    },
    axiosApi: {
      actions: {
        fetchById({
          id,
          params,
          callback,
        }: {
          id: number | string
          params?: Record<string, unknown>
          callback?: (response: AxiosResponse<ApiResponse<PagingResponse | Record<string, unknown>>>) => void
        }) {
          console.log('::fetchById::id', id)
          // @ts-expect-error useRepo is not defined
          return this.get(`services/${id}`, {
            params: { ...params },
            dataTransformer: (response: AxiosResponse<ApiResponse<PagingResponse | Record<string, unknown>>>) => {
              console.log('::fetchById::response', response)
              if (callback)
                callback(response)
              const { data: { result } } = response
              return result
            },
          }).catch((error) => {
            console.error('::fetchById::error', error)
          })
        },
        getPage({
          page = 1,
          itemsPerPage = 10,
          params = {},
          callback,
        }: {
          page?: number
          itemsPerPage?: number
          params?: Record<string, unknown>
          callback?: (response: AxiosResponse<ApiResponse<PagingResponse | Record<string, unknown>>>) => void
        }) {
          // @ts-expect-error useRepo is not defined
          return this.get('services', {
            params: {
              page,
              itemsPerPage,
              ...params,
            },
            dataTransformer: (response: AxiosResponse<ApiResponse<PagingResponse | Record<string, unknown>>>) => {
              if (callback)
                callback(response)
              const { data: { result } } = response
              if (isPagingResponse(result))
                return result.data
              else
                return result
            },
          }).catch((error) => {
            console.error('::getPage::error', error)
          })
        },
        update({
          id,
          data,
          callback,
        }: {
          id: number | string
          data: Record<string, unknown>
          callback?: (response: AxiosResponse<ApiResponse<Record<string, unknown>>>) => void
        }) {
          console.log('::update::id', id)
          // @ts-expect-error useRepo is not defined
          return this.put(`services/${id}`, data, {
            persistBy: 'insert',
            dataTransformer: (response: AxiosResponse<ApiResponse<Record<string, unknown>>>) => {
              if (callback)
                callback(response)
              console.log('::update::response', response)
              return response.data.result
            },
          }).catch((error) => {
            console.error('::update::error', error)
          })
        },
        destroy(idOrIds: number | number[]) {
          console.log('::Service:: delete', idOrIds)
          if (!Array.isArray(idOrIds)) {
            // @ts-expect-error useRepo is not define
            return this.delete(`services/${idOrIds}`, { delete: idOrIds })
          }
          else {
            console.log('::Service:: delete', idOrIds)
            // @ts-expect-error useRepo is not define
            return this.post('services/bulk-destroy', { ids: idOrIds }, { save: false }).then((result: Response) => {
              console.log('::delete::result', result)
              const { response: { data } } = result
              const notDeleted = data && data.result && Array.isArray(data.result) && data.result.length > 0 ? data.result : []
              useRepo(Service).destroy(idOrIds.filter(id => !notDeleted.includes(id)))
              return notDeleted
            })
          }
        },
      },
    },
  }
}
class ProjectCast extends CastAttribute {
  /*  get(value?: any) {
    if (!value)
      return null
    if (Array.isArray(value)) {
      return value.map((item) => {
        console.log('ProjectCast item', item)
        return new Project(item)
      })
    }
    return new Project(value)
  } */
  set(value?: any) {
    console.log('ProjectCast value', value)
    if (!value)
      return null
    if (Array.isArray(value)) {
      return value.map((item) => {
        console.log('ProjectCast item', item)
        return new Project(item)
      })
    }
    return new Project(value)
  }
}
