import { CustomProperties } from '@/models/customProperties.model'
import { I18nProperty } from '@i2pacg/bd-vue'
import { CastAttribute, Model } from 'pinia-orm'
import ImageModel from '../models/image.model'

export class I18nCast extends CastAttribute {
  get(value?: Record<string, string>): I18nProperty {
    if (!value)
      return I18nProperty.empty()
    return new I18nProperty(value)
  }
}
export class ImageCast extends CastAttribute {
  get(value?: Record<string, string>[]): ImageModel[] {
    if (!value)
      return []
    return value.map(item => new ImageModel(item))
  }
}
// timestamp cast
export class TimestampCast extends CastAttribute {
  get(value?: string): Date | null {
    if (!value)
      return null
    return new Date(value)
  }
}

// date year cast
export class CustomDateCast extends CastAttribute {
  get(value?: string): string | null {
    if (!value) {
      return null
    }
    // Parse the input date
    const date = new Date(value)

    // Extract the year, month, and day
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Ensure two digits for month
    const day = String(date.getDate()).padStart(2, '0') // Ensure two digits for day

    // Format the date as a string in YYYY-MM-DD format
    return `${year}-${month}-${day}`
  }
}

export class CustomPropertiesCast extends CastAttribute {
  get(value?: Record<string, string>): CustomProperties {
    if (!value)
      return new CustomProperties()
    return new CustomProperties(value)
  }
}
