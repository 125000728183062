/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

import type { App } from 'vue'
/* import BdCategoryLabel from '@/components/Admin/BdCategoryLabel.vue'
import CategoryField from '@/components/Admin/CategoryField.vue'
import SlugField from '@/components/Admin/SlugField.vue' */
import { i18n } from '@/plugins/i18n'
import { useBdVue } from '@i2pacg/bd-vue'
import { createHead } from '@unhead/vue'
import { MotionPlugin } from '@vueuse/motion'
// Plugins
import { setActivePinia } from 'pinia'
import { createVfm } from 'vue-final-modal'
import lineClamp from 'vue-line-clamp-3'
import VueSnip from 'vue-snip'
import timeago from 'vue-timeago3'
import ToastPlugin from 'vue-toast-notification'
import router from '../router'
import { vuetifyInstance } from './vuetify'
import 'vue-final-modal/style.css'
import 'unfonts.css'
import '@i2pacg/bd-vue/styles'
// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css'

const head = createHead()

export async function registerPlugins(app: App) {
  const { theme, language } = usePreferencesStore()
  console.log('Setting Lang', language)
  document.documentElement.lang = language
  document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr'
  /*   app.component('BdCategoryLabel', BdCategoryLabel)
  app.component('CategoryField', CategoryField)
  app.component('SlugField', SlugField) */
  app
    .use(head)
    .use(MotionPlugin)
    .use(ToastPlugin)
    .use(VueSnip)
    .use(timeago)
    .use(lineClamp)
    .use(i18n(language))
    .use(vuetifyInstance(theme, language))
    .use(createVfm())
    .use(router)
    .use(
      useBdVue({
        showLogs: false, // default: true
        components: { globallyRegister: true, exclude: [] },
        plugins: { exclude: ['customPlugin'] },
        directives: { exclude: [] },
      }),
    )
}
