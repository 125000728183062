import useUniversalStore from '@/data/stores/universal'

import { setupLayouts } from 'virtual:generated-layouts'
import { useVfm } from 'vue-final-modal'
import {
  createRouter,
  createWebHistory,
  type Router,
  type RouteRecordRaw,
} from 'vue-router'

import { routes } from 'vue-router/auto-routes'
/**
 * Recursively applies layouts to the given route and its children.
 * @param {RouteRecordRaw} route - The route to apply layouts to.
 * @returns {RouteRecordRaw} The modified route with layouts applied.
 */

function recursiveLayouts(route: RouteRecordRaw): RouteRecordRaw {
  if (route.children != null) {
    for (let i = 0; i < route.children.length; i++)
      route.children[i] = recursiveLayouts(route.children[i])
    return route
  }

  return setupLayouts([route])[0]
}
/* const mappedRoutes = routes.map(recursiveLayouts)
 */
/* console.log('mappedRoutes', routes.map(recursiveLayouts))
 */const router: Router = createRouter({
  history: createWebHistory(),
  routes: routes.filter(f => !f.meta || !f.meta.hidden).map(recursiveLayouts).filter((_) => {
    return true
  }),
  /*  extendRoutes(routes) {
    const mappedRoutes = routes.map(recursiveLayouts)
    return mappedRoutes
  } */ /*
  routes: routes.map(recursiveLayouts), */
  /* routes: routes.map(recursiveLayouts).filter((route, index, self) =>
    index === self.findIndex(r => (
      r.name === route.name && r.path === route.path
    )),
  ), */
  /*
  //make sure routes are unique
  routes: routes.map(recursiveLayouts).filter((route, index, self) =>
    index === self.findIndex((r) => (
      r.name === route.name && r.path === route.path
    ))
  ),

  extendRoutes(routes) {
    const mappedRoutes = routes.map(recursiveLayouts)
    return mappedRoutes
  }, */ /*    extendRoutes(routes) {
        return routes.map(recursiveLayouts);
    }, */

  scrollBehavior(to) {
    if (to.hash)
      return { el: to.hash }

    else
      return { top: 0 }
  },
})
router.beforeEach(async (to, from, next) => {
  if (from.name === to.name) {
    next()
    return
  }

  const vfm = useVfm()
  const {
    openedModals,
    closeAll,
  } = vfm
  if (openedModals.length > 0)
    await closeAll()
  const requiresAuth = to.meta?.requiresAuth ?? false
  if (requiresAuth) {
    const { isUserAuthenticated } = useAuth()
    if (await isUserAuthenticated()) {
      return next()
    }
    else {
      return next({
        name: 'home',
        query: { nextUrl: to.fullPath },
      })
    }
  }

  next()
})
// Workaround for https://github.com/vitejs/vite/issues/11804
router.onError((err, to) => {
  if (err?.message?.includes?.('Failed to fetch dynamically imported module')) {
    if (!localStorage.getItem('vuetify:dynamic-reload')) {
      console.log('Reloading page to fix dynamic import error')
      localStorage.setItem('vuetify:dynamic-reload', 'true')
      location.assign(to.fullPath)
    }
    else {
      console.error('Dynamic import error, reloading page did not fix it', err)
    }
  }
  else {
    console.error(err)
  }
})

router.isReady().then(() => {
  localStorage.removeItem('vuetify:dynamic-reload')
})

/* router.afterEach(async (to, from, next) => {
  if (from.name)
    if (to.name == 'home' && from.name != 'home') {
      console.log('home');
      const { mainSlider } = app();
    }
});
 */
export default router
