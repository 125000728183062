import type { ApiResponse, PagingResponse } from '@/interfaces'
import type { AllowedTypeWithRules, I18nProperty } from '@i2pacg/bd-vue'
import type { Response } from '@pinia-orm/axios'
import type { AxiosResponse } from 'axios'
import { isPagingResponse } from '@/helpers'
import { Model, useRepo } from 'pinia-orm'
import { ArrayCast, DateCast } from 'pinia-orm/casts'
import {
  Attr,
  BelongsTo,
  Cast,
  HasMany,
  HasOne,
  Str,
  Uid,
} from 'pinia-orm/decorators'
import { Project } from './project.model'
/*
export interface MedialCollection {
  AllowedTypeWithRules[]
} */
export class Category extends Model {
  static entity = 'categories'
  static primaryKey = 'id'
  static mediaCollections: Record<string, AllowedTypeWithRules[]> = {
    images: [
      {
        fileType: 'image/*',
        dimensions: {
          width: 1920,
          height: 1080,
        },
      },
    ],
  }

  @Attr(null) declare id: number | null
  @Attr() declare slug: string
  @Str(null) declare cover: string | null
  @Attr(null) declare resourceUrl: string | null
  @Attr(null) declare parentId: number | null
  @Attr() declare name: I18nProperty
  @Attr() declare description: I18nProperty
  @HasMany(() => Project, 'categoryId') declare projects: Project[] | null
  @HasOne(() => Category, 'id', 'parentId') declare parent: this | null
  @HasMany(() => Category, 'parentId') declare subCategories: this[] | null
  @Attr() declare createdAt: Date
  @Attr() declare updatedAt: Date

  static piniaOptions = { persist: true }

  static casts() {
    return {
      name: I18nCast,
      description: I18nCast,
      createdAt: DateCast,
      updatedAt: DateCast,
    }
  }

  static config = {
    axiosApi: {
      actions: {
        fetchById({
          id,
          params,
          callback,
        }: {
          id: number | string
          params?: Record<string, unknown>
          callback?: (response: AxiosResponse<ApiResponse<PagingResponse | Record<string, unknown>>>) => void
        }) {
          console.log('::fetchById::id', id)
          // @ts-expect-error useRepo is not defined
          return this.get(`categories/${id}`, {
            params: { ...params },
            dataTransformer: (response: AxiosResponse<ApiResponse<PagingResponse | Record<string, unknown>>>) => {
              console.log('::fetchById::response', response)
              if (callback)
                callback(response)
              const { data: { result } } = response
              return result
            },
          }).catch((error) => {
            console.error('::fetchById::error', error)
          })
        },
        getPage({
          page = 1,
          itemsPerPage = 10,
          params = {},
          callback,
        }: {
          page?: number
          itemsPerPage?: number
          params?: Record<string, unknown>
          callback?: (response: AxiosResponse<ApiResponse<PagingResponse | Record<string, unknown>>>) => void
        }) {
          // @ts-expect-error useRepo is not defined
          return this.get('categories', {
            params: {
              page,
              itemsPerPage,
              ...params,
            },
            dataTransformer: (response: AxiosResponse<ApiResponse<PagingResponse | Record<string, unknown>>>) => {
              if (callback)
                callback(response)
              const { data: { result } } = response
              if (isPagingResponse(result))
                return result.data
              else
                return result
            },
          }).catch((error) => {
            console.error('::getPage::error', error)
          })
        },
        update({
          id,
          data,
          callback,
        }: {
          id: number | string
          data: Record<string, unknown>
          callback?: (response: AxiosResponse<ApiResponse<Record<string, unknown>>>) => void
        }) {
          console.log('::update::id', id)
          // @ts-expect-error useRepo is not defined
          return this.put(`categories/${id}`, data, {
            persistBy: 'insert',
            dataTransformer: (response: AxiosResponse<ApiResponse<Record<string, unknown>>>) => {
              if (callback)
                callback(response)
              console.log('::update::response', response)
              return response.data.result
            },
          }).catch((error) => {
            console.error('::update::error', error)
          })
        },
        deleteBulk({ ids }: { ids: number[] }) {
          console.log('::deleteBulk::ids', ids)
          if (ids.length === 0)
            return Promise.resolve()
          if (ids.length === 1)
            // @ts-expect-error useRepo is not defined
            return this.delete(`categories/${ids[0]}`, { delete: ids[0] })

          // @ts-expect-error useRepo is not defined
          return this.post('categories/bulk-destroy', { ids }, { save: false }).then((result: Response) => {
            console.log('::delete::result', result)
            const { response: { data } } = result
            const notDeleted = data && data.result && Array.isArray(data.result) && data.result.length > 0 ? data.result : []
            /* destroy(ids.filter(id => !notDeleted.includes(id))) */
            useRepo(Category).destroy(ids.filter(id => !notDeleted.includes(id)))
            return notDeleted
          })
        },
      },
    },
  }
}
