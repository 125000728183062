import { I18nProperty } from '@i2pacg/bd-vue'
import { Model } from 'pinia-orm'
import { DateCast } from 'pinia-orm/casts'
import {
  Attr,
  Cast,
  HasMany,
  HasOne,
} from 'pinia-orm/decorators'

export default class MenuItemModel extends Model {
  static entity = 'menuItems'
  public static primaryKey = 'id'
  @Attr(null) declare id: number | null
  @Attr() declare name: I18nProperty
  @Attr() declare link: string
  @Attr() declare icon: string
  @Attr() declare resourceUrl: string | null
  @Attr() declare parentId: number | null
  @HasOne(() => MenuItemModel, 'id', 'parentId') declare parent: this | null
  @HasMany(() => MenuItemModel, 'parentId') declare items: this[]
  @Cast(() => DateCast) @Attr() declare createdAt: Date
  @Cast(() => DateCast) @Attr() declare updatedAt: Date

  static casts() {
    return { name: I18nCast }
  }
}
