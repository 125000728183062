export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('D:/Jobs/IdealTouch/idealtouch-website/idealtouch-frontend/src/pages/front/IndexPage.vue'),
    /* no children */
    meta: {
      "layout": "DefaultLayout",
      "permissions": [],
      "requiresAuth": false
    }
  },
  {
    path: '/:path(.*)',
    name: 'path----',
    component: () => import('D:/Jobs/IdealTouch/idealtouch-website/idealtouch-frontend/src/pages/front/NotFound.vue'),
    /* no children */
    meta: {
      "layout": "DefaultLayout",
      "permissions": [],
      "requiresAuth": false
    }
  },
  {
    path: '/contact-info',
    name: 'contact-info',
    component: () => import('D:/Jobs/IdealTouch/idealtouch-website/idealtouch-frontend/src/pages/front/ContactInfo.vue'),
    /* no children */
    meta: {
      "layout": "DefaultLayout",
      "permissions": [],
      "requiresAuth": false
    }
  },
  {
    path: '/jotun',
    name: 'jotun',
    component: () => import('D:/Jobs/IdealTouch/idealtouch-website/idealtouch-frontend/src/pages/front/Jotun.vue'),
    /* no children */
    meta: {
      "layout": "DefaultLayout",
      "permissions": [],
      "requiresAuth": false
    }
  },
  {
    path: '/projects',
    /* internal name: 'projects' */
    /* no component */
    children: [
      {
        path: '/projects/:category',
        name: 'projects--category',
        component: () => import('D:/Jobs/IdealTouch/idealtouch-website/idealtouch-frontend/src/pages/front/projects/[category].vue'),
        /* no children */
        meta: {
          "layout": "DefaultLayout",
          "permissions": [],
          "requiresAuth": false
        }
      },
      {
        path: '/projects/:category/:project',
        name: 'projects--category--project',
        component: () => import('D:/Jobs/IdealTouch/idealtouch-website/idealtouch-frontend/src/pages/front/projects/[category][project].vue'),
        /* no children */
        meta: {
          "layout": "DefaultLayout",
          "permissions": [],
          "requiresAuth": false
        }
      }
    ],
  },
  {
    path: '/services',
    /* internal name: 'services' */
    /* no component */
    children: [
      {
        path: '/services/:service',
        name: 'services--service',
        component: () => import('D:/Jobs/IdealTouch/idealtouch-website/idealtouch-frontend/src/pages/front/services/[service].vue'),
        /* no children */
        meta: {
          "layout": "DefaultLayout",
          "permissions": [],
          "requiresAuth": false
        }
      }
    ],
  },
  {
    path: '/we-care-program',
    name: 'we-care-program',
    component: () => import('D:/Jobs/IdealTouch/idealtouch-website/idealtouch-frontend/src/pages/front/WeCareProgram.vue'),
    /* no children */
    meta: {
      "layout": "DefaultLayout",
      "permissions": [],
      "requiresAuth": false
    }
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

