/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { install } from 'resize-observer'
import { createApp } from 'vue'
import { useRepo } from 'pinia-orm'
import { useStorage } from '@vueuse/core'
import MenuItemModel from './data/models/MenuItemModel'
import App from '@/App.vue'
import { Category } from '@/data/models/category.model'
import { Project } from '@/data/models/project.model'
import { Service } from '@/data/models/service.model'
import { registerPlugins } from '@/plugins'
import pinia from '@/data/stores'

if (typeof window !== 'undefined')
  install()

const app = createApp(App)
app.use(pinia)

useRepo(MenuItemModel).insert(useStorage('menuItems', [] as MenuItemModel[]).value)
useRepo(Service).insert(useStorage('services', [] as Service[]).value)
useRepo(Category).insert(useStorage('categories', [] as Category[]).value)
useRepo(Project).insert(useStorage('projects', [] as Project[]).value)

registerPlugins(app)

app.mount('#app')
