// Utilities
import { type PiniaPlugin, createPinia } from 'pinia'
import { createORM } from 'pinia-orm'
import { createPiniaOrmAxios } from '@pinia-orm/axios'
import axios from '@/plugins/axios'

const piniaOrm: PiniaPlugin = createORM()
// @ts-expect-error - This is a workaround for the lack of types in the axios plugin
piniaOrm().use(createPiniaOrmAxios({
  axios,
  baseURL: '/api',
}))
export default createPinia().use(piniaOrm)
