/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import 'vuetify/styles'
// Composables
import {
  type LocaleOptions,
  type ThemeDefinition,
  type VuetifyOptions,
  createVuetify,
} from 'vuetify'
import { type I18n, useI18n } from 'vue-i18n'
import { createVueI18nAdapter } from 'vuetify/lib/locale/adapters/vue-i18n.mjs'
import { i18n } from './i18n'
/* import { DEFAULT_PREFERENCES } from '@/constants' */
import icons from '@/plugins/icons'

type ThemeOptions = VuetifyOptions['theme']

function createLocale(locale: string): LocaleOptions {
  return {
    adapter: createVueI18nAdapter({
      i18n: i18n(locale),
      useI18n,
    }),
  }
}
const lightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#FFBF00',
    background: '#f8f9fa',
    surface: '#f7f5f4',
    success: '#85E783',
  },
}

const darkTheme: ThemeDefinition = {
  dark: true,
  colors: {
    primary: '#FFBF00',
    background: '#212529',
    surface: '#343A40',
    success: '#85E783',

  },
}
function createTheme(theme: string): ThemeOptions {
  return {
    defaultTheme: theme,
    variations: {
      colors: ['primary', 'secondary', 'error', 'info', 'success', 'warning', 'surface', 'background'],
      lighten: 5,
      darken: 5,
    },
    themes: {
      light: lightTheme,
      dark: darkTheme,
    },
  }
}
function createConfig(theme: string, language: string): VuetifyOptions {
  return {
    locale: createLocale(language),
    icons,
    theme: createTheme(theme),
  }
}

// Create and export Vuetify instance
/* export const vuetifyInstance = createVuetify(vuetifyConfig) */

export const vuetifyInstance = (theme: string, language: string) => createVuetify(createConfig(theme, language))
