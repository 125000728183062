import { ar as $vuetify } from 'vuetify/locale'
import { arLocale } from '@i2pacg/bd-vue'

import about from './pages/about.json'
import approach from './pages/approach.json'
import contactInfo from './pages/contactInfo.json'
import home from './pages/home.json'
import index from './pages/index.json'
import jotun from './pages/jotun.json'
import philosophy from './pages/philosophy.json'
import projects from './pages/projects.json'
import services from './pages/services.json'
import weCareProgram from './pages/weCareProgram.json'

import actions from './actions.json'
import common from './common.json'
import columns from './columns.json'
import crud from './crud.json'

import dashboard from './dashboardPages/dashboard.json'
import login from './dashboardPages/login.json'
import menus from './dashboardPages/menus.json'

import model from './entities/model.json'
import project from './entities/project.json'
import service from './entities/service.json'
import category from './entities/category.json'
import media from './entities/media.json'

export default {
  ...arLocale,
  actions,
  common,
  columns,
  crud,
  $vuetify,
  dashboardPages: {
    dashboard,
    login,
    menus,
  },
  pages: {
    about,
    approach,
    contactInfo,
    home,
    index,
    jotun,
    philosophy,
    projects,
    services,
    weCareProgram,
  },
  entities: {
    model,
    project,
    service,
    category,
    media,
  },
}
