import {
  type I18n,
  type I18nOptions,
  type VueMessageType,
  createI18n,
} from 'vue-i18n'
import { en as $vuetify } from 'vuetify/locale'
import { ar as $vuetifyAr } from 'vuetify/lib/locale/index.mjs'
import messages from '@/locales'

/* import messages from '@intlify/unplugin-vue-i18n/messages'
 */
/* import en from '@/locales/en.json'
import ar from '@/locales/ar.json'

console.log('en', en)
console.log('ar', ar) */

export type MessageSchema = typeof messages.en

declare module 'vue-i18n' {
  export interface DefineLocaleMessage extends MessageSchema { }
}
function customRule(choice: number): number {
  const lesTeen = choice <= 10 && choice > 2

  if (choice === 0)
    return 0

  if (choice === 1)
    return 1

  if (choice === 2)
    return 2

  if (lesTeen)
    return 3

  return 4
}
/* console.log(messages) */
function createOptions(locale: string): I18nOptions {
  return {
    legacy: false,
    locale,
    fallbackLocale: 'en',
    messages,
    missingWarn: true,
    pluralRules: { ar: customRule },
    modifiers: {
      arNumber: (str: VueMessageType) => {
        return Intl.NumberFormat('ar-EG').format(Number.parseInt(str.toString(), 10))
      },
    },
    numberFormats: {
      en: {
        decimal: {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
        percent: {
          style: 'percent',
          useGrouping: false,
        },
      },
      ar: {
        currency: {
          style: 'currency',
          currency: 'EGP',
          useGrouping: true,
          currencyDisplay: 'symbol',
        },
        decimal: {
          style: 'decimal',
          minimumSignificantDigits: 3,
          maximumSignificantDigits: 5,
        },
        percent: {
          style: 'percent',
          useGrouping: false,
        },
      },
    },
  }
}

/* const i18n = createI18n<false, typeof options>(options) */
function i18n(language: string): I18n {
  return createI18n(createOptions(language))
}
export { i18n }
