import type { ApiError } from '@/interfaces'
import type {
  AxiosError,
  AxiosInstance,
  AxiosInterceptorOptions,
  AxiosResponse,
  InternalAxiosRequestConfig,
  RawAxiosRequestHeaders,
} from 'axios'

import router from '@/router'
import axios from 'axios'
import {
  useModal,
  useVfm,
  VueFinalModal,
} from 'vue-final-modal'

const inModel = ref(false)
const axiosInstance: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_URL as string,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  } as RawAxiosRequestHeaders,
  withXSRFToken: true,
})

/* new AxiosQueue(axiosInstance)
 */
async function onResponse(response: AxiosResponse<unknown, unknown>): Promise<AxiosResponse<unknown, unknown>> {
  return response
}
/* let retryCounter = 0
 */
async function onResponseError(error: AxiosError<ApiError, InternalAxiosRequestConfig>): Promise<AxiosError<ApiError, InternalAxiosRequestConfig>> {
  console.log('::Global onResponseError::', error)
  console.log('::error.response?.status', error.response?.status)
  if (inModel.value) {
    console.log('::inModel:: we gonna try again', inModel.value)
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(axiosInstance.request(error.config!))
      }, 2000)
    })
  }
  if (error.response?.status === 401) {
    inModel.value = true
    /*   if (router.currentRoute.value.name === 'dashboard-login') {
      console.log('::router.currentRoute.value.name::', router.currentRoute.value.name)
      return Promise.reject(error)
    } */
    /*     return new Promise((resolve, reject) => {
      const {
        open,
        close,
        patchOptions,
      } = useModal({
        component: LoginForm,
        attrs: {
          isModal: true,
          onSubmit: (signInData: SignInData) => {
            console.log(':::signInData', signInData)
            patchOptions({ attrs: { loading: true } })
            useAuth()
              .signIn({ email: signInData.email, password: signInData.password })
              .then(() => {
                console.log('::SignIn Success')
                close()
                resolve(axiosInstance.request(error.config!))
              })
              .catch((error: unknown) => {
                console.log('::error', error)
                reject(error)
              })
              .finally(() => {
                console.log('::finally')
                inModel.value = false
                patchOptions({ attrs: { loading: false } })
              })
          },
          onCancel: () => {
            console.log('::onCancel::')
            close()
            reject(error)
            router.push({ name: 'dashboard-login' }).then(() => {
              console.log('::router.push::')
              inModel.value = false
            })
          },
        },
      })
      open()
    }) */
  }

  return Promise.reject(error)
}
const interceptorOptions: AxiosInterceptorOptions = { synchronous: false }
/*
 */
axiosInstance.interceptors.response.use(onResponse, onResponseError, interceptorOptions)
/* axiosInstance.interceptors.request.use(axiosQueueInterceptor, onRequestError, interceptorOptions)
 */
export default axiosInstance
