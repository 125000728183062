import type { IconOptions } from 'vuetify'
import bdIconsFengShui from '~icons/bd-icons/feng-shui'
import bdIconsFunnel from '~icons/bd-icons/funnel'
import bdIconsMapPinLine from '~icons/bd-icons/map-pin-line'
import carbonCategoryNewEach from '~icons/carbon/category-new-each'
import carbonCloudSatelliteServices from '~icons/carbon/cloud-satellite-services'
import carbonDeploymentPattern from '~icons/carbon/deployment-pattern'
import carbonIbmCloudProjects from '~icons/carbon/ibm-cloud-projects'
import carbonIbmEventProcessing from '~icons/carbon/ibm-event-processing'
import carbonReferenceArchitecture from '~icons/carbon/reference-architecture' //
import epFiles from '~icons/ep/files'
import flatColorIconsRedo from '~icons/flat-color-icons/redo'
import fluentFontDecrease24Filled from '~icons/fluent/font-decrease-24-filled'
import fluentFontIncrease24Filled from '~icons/fluent/font-increase-24-filled'
import fluentMdl2PageHeader from '~icons/fluent-mdl2/page-header'
import iconParkTwotoneBydesign from '~icons/icon-park-twotone/bydesign'
import letsIconAlarmDuotone from '~icons/lets-icons/alarm-duotone'
import letsIconsArrowLeftLight from '~icons/lets-icons/arrow-left-light'
import letsIconsArrowRightLight from '~icons/lets-icons/arrow-right-light'
import letsIconsBubbleLight from '~icons/lets-icons/bubble-light'
import letsIconsChartDuotone from '~icons/lets-icons/chart-duotone'
import letsIconsDirectionDuotone from '~icons/lets-icons/direction-duotone'
import letsIconsEmailLight from '~icons/lets-icons/e-mail-light'
import letsIconsHomeDuotone from '~icons/lets-icons/home-duotone'
import letsIconsLayersDuoTone from '~icons/lets-icons/layers-duotone'
import letsIconLineDuotone from '~icons/lets-icons/line-duotone'
import letsIconsMapDuotoneLine from '~icons/lets-icons/map-duotone-line'
import letsIconsPhoneDuotone from '~icons/lets-icons/phone-duotone'
import letsIconsPhoneDuotoneLine from '~icons/lets-icons/phone-duotone-line'
import materialSymbolsFormatH1 from '~icons/material-symbols/format-h1'
import materialSymbolsMailOutline from '~icons/material-symbols/mail-outline'
import materialSymbolsPlannerReview from '~icons/material-symbols/planner-review'
import materialSymbolsLightThreadUnreadOutlineRounded from '~icons/material-symbols-light/thread-unread-outline-rounded'
import materialSymbolsLightTransform from '~icons/material-symbols-light/transform'
import mdiAccount from '~icons/mdi/account'
import mdiAccountGroup from '~icons/mdi/account-group'
import mdiAlertCircleOutline from '~icons/mdi/alert-circle-outline'
import mdiAlignVerticalCenter from '~icons/mdi/align-vertical-center'
import mdiBell from '~icons/mdi/bell'
import mdiCheck from '~icons/mdi/check'
import mdiCheckboxMultipleBlankCircle from '~icons/mdi/checkbox-multiple-blank-circle'
import mdiCheckboxMultipleBlankCircleOutline from '~icons/mdi/checkbox-multiple-blank-circle-outline'
import mdiCheckboxMultipleMarkedCircleOutline from '~icons/mdi/checkbox-multiple-marked-circle-outline'
import mdiChevronDown from '~icons/mdi/chevron-down'
import mdiChevronLeft from '~icons/mdi/chevron-left'
import mdiChevronRight from '~icons/mdi/chevron-right'
import mdiChevronUp from '~icons/mdi/chevron-up'
import mdiClose from '~icons/mdi/close'
import mdiCloseBox from '~icons/mdi/close-box'
import mdiCodeJson from '~icons/mdi/code-json'
import mdiCog from '~icons/mdi/cog'
import mdiDelete from '~icons/mdi/delete'
import mdiEarth from '~icons/mdi/earth'
import mdiEye from '~icons/mdi/eye'
import mdiEyeOff from '~icons/mdi/eye-off'
import mdiFlag from '~icons/mdi/flag'
import mdiGlobeArrowLeft from '~icons/mdi/globe-arrow-left'
import mdiGlobeArrowRight from '~icons/mdi/globe-arrow-right'
import mdiGrid from '~icons/mdi/grid'
import mdiHome from '~icons/mdi/home'
import mdiKey from '~icons/mdi/key'
import mdiKeyChange from '~icons/mdi/key-change'
import mdiLogout from '~icons/mdi/logout'
import mdiMagnify from '~icons/mdi/magnify'
import mdiMenu from '~icons/mdi/menu'
import mdiMessage from '~icons/mdi/message'
import mdiPencil from '~icons/mdi/pencil'
import mdiPlus from '~icons/mdi/plus'
import mdiRefresh from '~icons/mdi/refresh'
import mdiReload from '~icons/mdi/reload'
import mdiViewGridPlus from '~icons/mdi/view-grid-plus'
import openmojiCheckMark from '~icons/openmoji/check-mark'
import pepiconsPencilContract from '~icons/pepicons-pencil/contract'
import pepiconsPrintHandshakeCircleFilled from '~icons/pepicons-print/handshake-circle-filled'
import phYinYangLight from '~icons/ph/yin-yang-light'
import riContractUpDownLine from '~icons/ri/contract-up-down-line'
import solarAtomBoldDuoTone from '~icons/solar/atom-bold-duotone'
import streamlineArrowRoadmap from '~icons/streamline/arrow-roadmap'
import uimRefresh from '~icons/uim/refresh'
import vscodeIconsFileTypeMediawiki from '~icons/vscode-icons/file-type-mediawiki'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

const icons: IconOptions = {
  defaultSet: 'mdi',
  sets: { mdi },
  aliases: {
    ...aliases,
    mdiMenu,
    mdiRefresh,
    mdiCloseBox,
    mdiAlertCircleOutline,
    mdiPlus,
    mdiHome,
    mdiViewGridPlus,
    mdiCodeJson,
    mdiEarth,
    mdiLogout,
    mdiAccount,
    mdiAccountGroup,
    mdiKey,
    mdiDelete,
    mdiPencil,
    mdiEye,
    mdiEyeOff,
    mdiGrid,
    mdiBell,
    mdiFlag,
    mdiMessage,
    mdiKeyChange,
    mdiAlignVerticalCenter,
    mdiReload,
    mdiChevronRight,
    mdiChevronLeft,
    mdiClose,
    mdiMagnify,
    mdiCheck,
    mdiCog,
    mdiChevronDown,
    mdiChevronUp,
    'lets-icons-home-duotone': letsIconsHomeDuotone,
    'lets-icons-line-duotone': letsIconLineDuotone,
    'lets-icons-alarm-duotone': letsIconAlarmDuotone,
    'lets-icons-bubble-light': letsIconsBubbleLight,
    'lets-icons-chart-duotone': letsIconsChartDuotone,
    'lets-icons-phone-duotone': letsIconsPhoneDuotone,
    'lets-icons-direction-duotone': letsIconsDirectionDuotone,
    'pepicons-print-handshake-circle-filled': pepiconsPrintHandshakeCircleFilled,
    'icon-park-twotone-bydesign': iconParkTwotoneBydesign,
    'bd-icons-feng-shui': bdIconsFengShui,
    'solar-atom-bold-duotone': solarAtomBoldDuoTone,
    'lets-icons-layers-duotone': letsIconsLayersDuoTone,
    'bd-icons-funnel': bdIconsFunnel,
    'material-symbols-light-thread-unread-outline-rounded': materialSymbolsLightThreadUnreadOutlineRounded,
    'streamline-arrow-roadmap': streamlineArrowRoadmap,
    'carbon-ibm-event-processing': carbonIbmEventProcessing,
    'carbon-deployment-pattern': carbonDeploymentPattern,
    'material-symbols-light-transform': materialSymbolsLightTransform,
    'uim-refresh': uimRefresh,
    'lets-icons-arrow-right-light': letsIconsArrowRightLight,
    'lets-icons-arrow-left-light': letsIconsArrowLeftLight,
    'lets-icons-phone-duotone-line': letsIconsPhoneDuotoneLine,
    'lets-icons-e-mail-light': letsIconsEmailLight,
    'material-symbols-mail-outline': materialSymbolsMailOutline,
    'lets-icons-map-duotone-line': letsIconsMapDuotoneLine,
    'ph-yin-yang-light': phYinYangLight,
    'ri-contract-up-down-line': riContractUpDownLine,
    'pepicons-pencil-contract': pepiconsPencilContract,
    'bd-icons-map-pin-line': bdIconsMapPinLine,
    'carbon-cloud-satellite-services': carbonCloudSatelliteServices,
    'mdi-globe-arrow-left': mdiGlobeArrowLeft,
    'mdi-globe-arrow-right': mdiGlobeArrowRight,
    'fluent-mdl2-page-header': fluentMdl2PageHeader,
    'fluent-font-increase-24-filled': fluentFontIncrease24Filled,
    'fluent-font-decrease-24-filled': fluentFontDecrease24Filled,
    'material-symbols-format-h1': materialSymbolsFormatH1,
    'carbon-reference-architecture': carbonReferenceArchitecture,
    'material-symbols-planner-review': materialSymbolsPlannerReview,
    'mdi-checkbox-multiple-blank-circle-outline': mdiCheckboxMultipleBlankCircleOutline,
    'mdi-checkbox-multiple-blank-circle': mdiCheckboxMultipleBlankCircle,
    'mdi-checkbox-multiple-marked-circle-outline': mdiCheckboxMultipleMarkedCircleOutline,
    'carbon-category-new-each': carbonCategoryNewEach,
    'carbon-ibm-cloud-projects': carbonIbmCloudProjects,
    'ep-files': epFiles,
    'vscode-icons-file-type-mediawiki': vscodeIconsFileTypeMediawiki,
    'openmoji-check-mark': openmojiCheckMark,
    'flat-color-icons-redo': flatColorIconsRedo,
  },
}
export default icons
