<script lang="ts" setup>
import {
  useSessionStorage,
  useStorageAsync,
  useWindowScroll,
} from '@vueuse/core'

/* const { global } = useTheme() */
const { scrolled } = toRefs(universal())
const { y } = useWindowScroll()
const { mdAndDown } = useDisplay()
const { global } = useTheme()
const { theme, language } = toRefs(usePreferencesStore())
const { locale } = useI18n()
watch(y, (value) => {
  scrolled.value = value > (mdAndDown.value ? 10 : 100)
})
watch(language, () => {
  locale.value = language.value
  document.documentElement.lang = language.value
  document.documentElement.dir = language.value === 'ar' ? 'rtl' : 'ltr'
})
watch(theme, () => {
  global.name.value = theme.value
})
</script>

<template>
  <v-app>
    <router-view />
    <!--  <v-card
      position="fixed"
      width="500"
      class="ma-4"
      style="z-index: 5000;"
    >
      In Store
      <br>
      {{ theme }}
      <br>
      {{ language }}
      <br>
      in storage
      <br>
    </v-card> -->
  </v-app>
</template>

<style>
.bd-file-input {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    overflow: hidden;
    height: auto;
}
.bd-item-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    > div {
        > img {
            pointer-events: auto;
            cursor: grab;
            &:focus {
                cursor: grabbing;
            }
            &:active {
                cursor: grabbing;
            }
        }
        > div {
            pointer-events: auto;
            cursor: grab;
            &:focus {
                cursor: grabbing;
            }
            &:active {
                cursor: grabbing;
            }
        }
    }
}
:root {
    --white: #fff;
}
</style>
