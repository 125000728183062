import type { ApiResponse, PagingResponse } from '@/interfaces'
import type { AllowedTypeWithRules, I18nProperty } from '@i2pacg/bd-vue'
import type { Response } from '@pinia-orm/axios'
import type { AxiosResponse } from 'axios'
import type ImageModel from './image.model'
import { isPagingResponse } from '@/helpers'
import { Model, useRepo } from 'pinia-orm'
import {
  Attr,
  Cast,
  HasOne,
  MorphMany,
  Str,
  Uid,
} from 'pinia-orm/decorators'
import { Category } from './category.model'
import { Media } from './media.model'

export class Project extends Model {
  static entity = 'projects'
  static primaryKey = 'id'

  @Uid() declare id: number | null
  @Attr() declare slug: string
  @Attr() declare startDate: string | null
  @Attr() declare endDate: string | null
  @Str('') declare cover: string
  @Attr(null) declare categoryId: number | null
  @HasOne(() => Category, 'id', 'categoryId') declare category: Category
  @Attr() declare name: I18nProperty
  @Attr() declare images: ImageModel[]
  @Attr() declare description: I18nProperty
  @Attr() declare createdAt: Date
  @Attr() declare updatedAt: Date
  @MorphMany(() => Media, 'modelId', 'modelType') declare media: Media[]

  static casts() {
    return {
      name: I18nCast,
      description: I18nCast,
      images: ImageCast,
      createdAt: TimestampCast,
      updatedAt: TimestampCast,
      startDate: CustomDateCast,
      endDate: CustomDateCast,
    }
  }

  static config = {
    crudConfig: {
      itemsPerPage: 10,
      mediaCollections: {
        images: [
          {
            fileType: 'image/*',
            dimensions: {
              width: 1920,
              height: 1080,
            },
          },
        ],
      },
      columns: {
        id: {
          type: 'number',
          title: 'columns.default.id.header',
          header: { width: 24, align: 'center' },
          cell: { type: 'number' },
          field: { type: 'number', hidden: true },
        },
        slug: {
          type: 'string',
          title: 'columns.default.slug.header',
          header: { width: 24, align: 'center' },
          cell: { type: 'string' },
          field: {
            type: 'string',
            component: 'SlugField',
          },
        },
        cover: {
          type: 'image',
          title: 'columns.default.cover.header',
          header: { width: 24, align: 'center' },
          cell: { type: 'image' },
        },
        name: {
          type: 'string',
          title: 'columns.default.name.header',
          header: { align: 'start' },
          cell: { type: 'string' },
          translatable: true,
          field: {
            type: 'string',
            rules: {
              en: [
                (v: string) =>
                  !!v
                  || 'crud.rules.required',
              ],
              ar: [
                (v: string) =>
                  !!v
                  || 'crud.rules.required',
              ],
            },
            label: 'columns.default.name.field.label',
          },
        },
        category: {
          type: 'string',
          relation: 'category',
          title: 'entities.project.columns.category.header',
          header: { align: 'start' },
          cell: { type: 'string', component: 'BdCategoryLabel' },
        },
        description: {
          type: 'text',
          translatable: true,
          title: 'columns.default.description.header',
          header: { align: 'start' },
          cell: { type: 'string' },
          field: { type: 'text', label: 'columns.default.description.field.label' },
        },
        startDate: {
          type: 'date',
          title: 'entities.project.columns.startDate.header',
          header: { align: 'start' },
          cell: { type: 'date' },
          field: { type: 'date' },
        },
        endDate: {
          type: 'date',
          title: 'entities.project.columns.endDate.header',
          header: { align: 'start' },
          cell: { type: 'date' },
          field: { type: 'date' },
        },
        createdAt: {
          type: 'datetime',
          title: 'columns.default.createdAt.header',
          header: { align: 'start' },
          cell: { type: 'datetime' },
          field: { type: 'datetime', hidden: true },
        },
        updatedAt: {
          type: 'datetime',
          title: 'columns.default.updatedAt.header',
          header: { align: 'start' },
          cell: { type: 'datetime' },
          field: { type: 'datetime', hidden: true },
        },
      },
    },
    axiosApi: {
      actions: {
        fetchById({
          id,
          params,
          callback,
        }: {
          id: number | string
          params?: Record<string, unknown>
          callback?: (response: AxiosResponse<ApiResponse<PagingResponse | Record<string, unknown>>>) => void
        }) {
          console.log('::fetchById::id', id)
          // @ts-expect-error useRepo is not defined
          return this.get(`projects/${id}`, {
            params: { ...params },
            dataTransformer: (response: AxiosResponse<ApiResponse<PagingResponse | Record<string, unknown>>>) => {
              console.log('::fetchById::response', response)
              if (callback)
                callback(response)
              const { data: { result } } = response
              return result
            },
          }).catch((error) => {
            console.error('::fetchById::error', error)
          })
        },
        getPage({
          page = 1,
          itemsPerPage = 10,
          params = {},
          callback,
        }: {
          page?: number
          itemsPerPage?: number
          params?: Record<string, unknown>
          callback?: (response: AxiosResponse<ApiResponse<PagingResponse | Record<string, unknown>>>) => void
        }) {
          // @ts-expect-error useRepo is not defined
          return this.get('projects', {
            params: {
              page,
              itemsPerPage,
              ...params,
            },
            dataTransformer: (response: AxiosResponse<ApiResponse<PagingResponse | Record<string, unknown>>>) => {
              if (callback)
                callback(response)
              const { data: { result } } = response
              if (isPagingResponse(result))
                return result.data
              else
                return result
            },
          }).catch((error) => {
            console.error('::getPage::error', error)
          })
        },
        update({
          id,
          data,
          callback,
        }: {
          id: number | string
          data: Record<string, unknown>
          callback?: (response: AxiosResponse<ApiResponse<Record<string, unknown>>>) => void
        }) {
          console.log('::update::id', id)
          // @ts-expect-error useRepo is not defined
          return this.put(`projects/${id}`, data, {
            persistBy: 'insert',
            dataTransformer: (response: AxiosResponse<ApiResponse<Record<string, unknown>>>) => {
              if (callback)
                callback(response)
              console.log('::update::response', response)
              return response.data.result
            },
          }).catch((error) => {
            console.error('::update::error', error)
          })
        },
        destroy(idOrIds: number | number[]) {
          console.log('::Project:: delete', idOrIds)
          if (!Array.isArray(idOrIds)) {
            // @ts-expect-error useRepo is not define
            return this.delete(`projects/${idOrIds}`, { delete: idOrIds })
          }
          else {
            console.log('::Project:: delete', idOrIds)
            // @ts-expect-error useRepo is not define
            return this.post('projects/bulk-destroy', { ids: idOrIds }, { save: false }).then((result: Response) => {
              console.log('::delete::result', result)
              const { response: { data } } = result
              const notDeleted = data && data.result && Array.isArray(data.result) && data.result.length > 0 ? data.result : []
              useRepo(Project).destroy(idOrIds.filter(id => !notDeleted.includes(id)))
              return notDeleted
            })
          }
        },
      },
    },
  }
}
