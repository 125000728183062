import { isObject } from '@vueuse/core'
import type { RuleFunction, ValidationRule } from '@i2pacg/bd-vue'
import { VTextField } from 'vuetify/components'
import type { PagingResponse, ValidationError } from './interfaces'

export const isOdd = n => Math.abs(n % 2) === 1
export function slugIt(str) {
  // check if word is not english
  return str
    .toString()
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '')
}
export function guid() {
  const segment = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  return `${segment()}${segment()}-${segment()}-${segment()}-${segment()}-${segment()}${segment()}${segment()}`
}
export const goodObject = (obj: unknown): obj is Record<string, any> => isObject(obj)
export const hasName = (o: object): o is { name: string } => isObject(o) && 'name' in o

export const hasId = (o: unknown): o is { id: string | number } => isObject(o) && 'id' in o
export const hasSlug = (o: unknown): o is { slug: string } => isObject(o) && 'slug' in o
export const hasMessage = (o: unknown): o is { message: string } => isObject(o) && 'message' in o
export const isValidationError = (o: unknown): o is ValidationError => isObject(o) && 'message' in o && 'errors' in o
/*
type ValidationRule$1 = ValidationResult | PromiseLike<ValidationResult> | ((value: any) => ValidationResult) | ((value: any) => PromiseLike<ValidationResult>);

 */

export function isValidationRule(value: any): value is ValidationRule {
  return (
    value != null
    && (
      typeof value === 'function'
      || (typeof value === 'object' && typeof value.then === 'function')
      || typeof value !== 'object'
    )
  )
}
export const isI18nRules = (o: unknown): o is Record<string, Array<RuleFunction>> => isObject(o) && Object.keys(o).every(key => Array.isArray(o[key]))

export const isRuleFunction = (o: unknown): o is RuleFunction => typeof o === 'function'

export const isPagingResponse = (o: unknown): o is PagingResponse => isObject(o) && 'data' in o && 'currentPage' in o && 'firstPageUrl' in o && 'from' in o && 'lastPage' in o && 'lastPageUrl' in o && 'links' in o && 'nextPageUrl' in o && 'path' in o && 'perPage' in o && 'prevPageUrl' in o && 'to' in o && 'total' in o

export const keyOrDefault = <T, D>(item: T, key: string, defaultValue: D): D => item && item[key] ? item[key] : defaultValue
