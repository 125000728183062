import { useMisc } from '@i2pacg/bd-vue'
import { defineStore } from 'pinia'
import type { Ref, ShallowRef } from 'vue'
import {
  useDisplay,
  useLocale,
  useTheme,
} from 'vuetify'

/* function isNull(object: unknown): object is null {
  return object === null || object == undefined || object == 'undefined'
}
function isJson(str: string): boolean {
  try {
    JSON.parse(str)
  }
  catch (e) {
    return false
  }
  return true
} */
interface UniversalStore {
  drawer: Ref<boolean>
  scrolled: Ref<boolean>
}

const useUniversalStore = defineStore('universal', () => {
  const scrolled = ref(false)
  const drawer = ref(false)
  const globalStore: UniversalStore = {
    scrolled,
    drawer,
  }
  return globalStore
})

export default useUniversalStore
