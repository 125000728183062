import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'

import type { ToRefs } from 'vue'
import type { Preferences } from '@/interfaces/preferences'

export const usePreferencesStore = defineStore('preferences', () => {
  const defaultPreferences = toRefs(useLocalStorage<Preferences>('preferences', {
    language: 'en',
    theme: 'dark',
  }))

  const preferencesStore: ToRefs<Preferences> = defaultPreferences
  return preferencesStore
})
